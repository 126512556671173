/* eslint-disable */

(function($) {
   'use strict';
      /*======================= 
        02. Smooth scrolling
      ======================*/
      window.initSmoothScroll = function() {
        $(document).on('click', 'a[href^="#"]', function (event) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset()?.top
          }, 500);
        });
      }
      /*======================= 
        03. Brand Logo Carousel Slider
      ======================*/
      window.initBrandCarousel = function() {
        $('.brand-logos').owlCarousel({
            loop:true,
            autoplay:true,
            autoPlaySpeed: 500,
            autoplayTimeout: 500,
            items: 2,
            margin:10,
            nav: false,
            dots: false,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1            },
                600:{
                    items:3
                },
                1000:{
                    items:4
                }
            }
        });
      }
      
      /*======================= 
        04. Roadmap Slider Carousel Slider
      ======================*/
      window.initRoadmapCarousel = function() {
        $('.roadmap-slider').owlCarousel({
            loop:true,
            autoplay:true,
            autoplayTimeout:6000,
            margin:0,
            nav: false,
            dot: true,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:4
                },
                1350:{
                    items:5
                }
            }
        });
      }

      /*=======================
        05. Treasury animation
      ======================*/
      window.initTreasuryAnimation = function() {
        jQuery(document).ready(function(){
            setTimeout(function(){
                jQuery('.diamond-animation').addClass('done');
            }, 6000);
            setTimeout(function(){
                jQuery('.diamond-animation .main').addClass('active');
            }, 1000);
            setTimeout(function(){
                jQuery('.inside-bitcoin').addClass('active');
            }, 3000);
            setTimeout(function(){
                jQuery('.inside-bitcoin').addClass('spincoin');
            }, 5000);
            setTimeout(function(){
                jQuery('.diamond-animation .lines').addClass('active');
            }, 6000);
            setTimeout(function(){
                jQuery('.diamond-animation .top-coin').addClass('active');
            }, 3000);
             setTimeout(function(){
                jQuery('.diamond-animation .outer-Orbit').addClass('active');
            }, 5000);
        });
      }

      /*=======================
        06. Events animation
      ======================*/
      window.initEventsAnimation = function() {
       jQuery(document).ready(function(){
            setTimeout(function(){
                jQuery('.events.treasury-animation').addClass('start-animation');
            }, 1000);
            setTimeout(function(){
                jQuery('.events.treasury-animation .lines').addClass('active');
            }, 2000);
        });
      }


      /*=======================
        07. Incubator animation
      ======================*/
      window.initIncubatorAnimation = function() {
        jQuery(document).ready(function(){
            setTimeout(function(){
                jQuery('.treasury-animation').addClass('start-animation');
            }, 1000);
            setTimeout(function(){
                jQuery('.treasury-animation .lines').addClass('active');
            }, 2000);
        });
      }

      /*===============================
        08. Expert Slider Carousel Slider
      ==================================*/
      window.initExpertSlider = function() {
        $('.expert-slider').owlCarousel({
            loop:true,
            autoplay:true,
            autoplayTimeout:6000,
            margin:0,
            nav: true,
            dot: false,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            responsiveClass:true,

              responsive:{
                  0:{
                      items:1
                  },
                  600:{
                      items:1
                  },
                  1000:{
                      items:1
                  },
                  1350:{
                      items:1
                  }
              }
          });
      }

      /*===============================
        05. Blog Slider Carousel Slider
      ==================================*/
      // $('.blog-slider.with-navigation').owlCarousel({
      //     loop:false,
      //     autoplay:true,
      //     autoplayTimeout:6000,
      //     margin:0,
      //     nav: true,
      //     dot: true,
      //     responsiveClass:true,
      //     responsive:{
      //         0:{
      //             items:1,
      //             nav: false
      //         },
      //         600:{
      //             items:1,
      //             nav: false
      //         },
      //         1000:{
      //             items:2,
      //             nav: true
      //         },
      //         1350:{
      //             items:3,
      //             nav: true
      //         }
      //     }
      // });
      // $('.blog-slider').owlCarousel({
      //     loop:false,
      //     autoplay:true,
      //     autoplayTimeout:6000,
      //     margin:0,
      //     nav: false,
      //     dot: true,
      //     responsiveClass:true,
      //     responsive:{
      //         0:{
      //             items:1
      //         },
      //         600:{
      //             items:1
      //         },
      //         1000:{
      //             items:2
      //         },
      //         1350:{
      //             items:3
      //         }
      //     }
      // });
      // $('.services-slider').owlCarousel({
      //     loop:false,
      //     autoplay:true,
      //     autoplayTimeout:6000,
      //     margin:0,
      //     nav: false,
      //     dot: true,
      //     responsiveClass:true,
      //     responsive:{
      //         0:{
      //             items:1
      //         },
      //         600:{
      //             items:1
      //         },
      //         1000:{
      //             items:2
      //         },
      //         1350:{
      //             items:3
      //         }
      //     }
      // });

      // $('.teamslider').owlCarousel({
      //     loop:true,
      //     autoplay:true,
      //     autoplayTimeout:6000,
      //     margin:20,
      //     nav: true,
      //     dot: false,
      //     responsiveClass:true,
      //     responsive:{
      //         0:{
      //             items:1,
      //             dot: false
      //         },
      //         600:{
      //             items:2,
      //             dot: false
      //         },
      //         1000:{
      //             items:3,
      //             dot: false
      //         },
      //         1350:{
      //             items:3,
      //             dot: false
      //         }
      //     }
      // });
      // if($('.roadmap-live-slider').length){
      //   $('.roadmap-live-slider').slick({
      //     centerMode: true,
      //     slidesToShow: 5,
      //     arrows: true,
      //     dots: false,
      //     responsive: [
      //       {
      //         breakpoint: 1199,
      //         settings: {
      //           arrows: true,
      //           dots: false,
      //           centerMode: true,
      //           slidesToShow: 3
      //         }
      //       },
      //       {
      //         breakpoint: 768,
      //         settings: {
      //           arrows: true,
      //           dots: false,
      //           centerMode: true,
      //           slidesToShow: 1
      //         }
      //       },
      //       {
      //         breakpoint: 480,
      //         settings: {
      //           arrows: false,
      //           dots: true,
      //           centerMode: true,
      //           slidesToShow: 1
      //         }
      //       }
      //     ]
      //   });
      // }
      

      /*===============================
        07. Mobile Menu
      ==================================*/
      if($(window).width() < 767){
        jQuery(document).on("click", ".menu-icon", function() {
          jQuery(this).toggleClass('active');
          jQuery('nav').slideToggle();
          jQuery('nav ul li a').on("click", function(){
            jQuery('.menu-icon').removeClass('active');
            jQuery('nav').hide();
          });
        });
      }

      setTimeout(function(){
          jQuery('.video-section').addClass('loaded');
      }, 1500);

     if($('.fancybox-media').length){
        $('.fancybox-media').fancybox({
          openEffect  : 'none',
          closeEffect : 'none',
          helpers : {
            media : {}
          }
        });
      }


  $(window).scroll(function() {
    if($(window).scrollTop() > $("header").height()){
      $("header").addClass('fixed');
    } else{
      var isFixed = $("header").hasClass("fix-it");
      if (isFixed) return;
      $("header").removeClass('fixed');
    }
  });
      
})(window.jQuery);

equalheight = function(container){
  var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
  $(container).each(function() {

   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;

   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}

// $(window).load(function() {
//   equalheight('.benefit-box');
// });


// $(window).resize(function(){
//   equalheight('.benefit-box');
// });

// $(document).ready(function($) {
//   function animateElements() {
//     $('.progressbar').each(function() {
//       var elementPos = $(this).offset().top;
//       var topOfWindow = $(window).scrollTop();
//       var percent = $(this).find('.circle').attr('data-percent');
//       var percentage = parseInt(percent, 10) / parseInt(100, 10);
//       //alert(percent + ' + ' +percentage);
//       var animate = $(this).data('animate');
//       if (elementPos < topOfWindow + $(window).height() - 100 && !animate) {
//         $(this).data('animate', true);
//         $(this).find('.circle').circleProgress({
//           startAngle: -Math.PI / 2,
//           value: percent / 100,
//           thickness: 10,
//           size: 160,
//           emptyFill: "#a5a5a4",
//           fill: {
//             color: '#fbbd17'
//           }
//         }).on('circle-animation-progress', function(event, progress, stepValue) {
//           //alert(stepValue);
//           $(this).find('div').text((stepValue * 100).toFixed(1) + "%");
//         }).stop();
//       }
//     });
//   }

//   // Show animated elements
//   animateElements();
//   $(window).scroll(animateElements);
// });

// $(document).ready(function($) {
//   function benefitanimateElements() {
//     if($('.benefit-section').length > 0){
//         var benefitPos = $('.benefit-section').offset().top;
//         var topOfWindow = $(window).scrollTop();
//         if (benefitPos < topOfWindow + $(window).height() / 3) {
//           $('.benefit-section').addClass('start-animation');
//         }
//     }
//   }
//   // Show animated elements
//   benefitanimateElements();
//   $(window).scroll(benefitanimateElements);
// });
