/* eslint-disable */
export const NAVBAR_LINKS = [
  { label: 'POT-FOLIO', to: '/potfolio' },
];

export const SUBMENU_LINKS = [
  { label: 'Profile', to: '/profile' },
  { label: 'Logout', to: '/' },
];

export const PROFILE_LINKS = [
  { label: 'Profile', to: '/profile', needsAuth: true },
  { label: 'Refferal Code', to: '/referral', needsAuth: true },
  { label: 'Change Password', to: '/change-password', needsAuth: false },
  { label: 'Forgot Password', to: '/forgot-password', needsAuth: false },
];

export const FORGOT_PASSWORD_LINKS = [
  { label: 'Profile', to: '/profile', needsAuth: true },
  { label: 'Change Password', to: '/change-password', needsAuth: true },
  { label: 'Forgot Password', to: '/forgot-password', needsAuth: false },
];
