import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
} from 'axios';
/* eslint-disable import/no-cycle */
import {
  onResponse,
  onResponseFailure,
  onRequest,
  onRequestFailure,
} from './interceptors';

const instance: AxiosInstance = axios.create({
  baseURL: 'https://aklabs-api-0656cc5ebbcb.herokuapp.com/api/',
  timeout: 10000,
});

instance.interceptors.request.use(
  (request: AxiosRequestConfig) => onRequest(request),
  (reason: AxiosError) => onRequestFailure(reason),
);

instance.interceptors.response.use(
  (response: AxiosResponse) => onResponse(response),
  (reason: AxiosError) => onResponseFailure(reason),
);

export { instance };
