<template>
  <!--footer Start-->
  <footer class="footer-3">
    <div class="container">
      <div class="row">
        <div class="col-md-6 footer-box-1">
          <div class="footer-logo">
            <a href="#" title="" @click="scrollToTop()"><img width="64" :src="logo" alt="Ak Labs"></a>
            <a class="ml-4" href="https://www.dextools.io/app/en/bnb/pair-explorer/0xf93df36378c7c9d4d14c372a4275905d472c8c6e" title="Dextools" target="_blank"><img width="150" :src="dextools" alt="Dextools"></a>
          </div>
          <p>Join the AK-LABS ecosystem and gain privileged access to exclusive benefits while sharing in our success. Our revolutionary initiative was specially designed to bring value to all individuals supporting us and our vision. With this commitment at our core, we are dedicated to maximizing the utility of our approach, continuously evolving and progressively broadening our features, to ensure they always serve our common interests.</p>
        </div>
        <div class="col-md-6 footer-box-2">
          <ul class="footer-menu onepage">
            <li><a href="" @click.prevent="$router.push('/')">Home</a></li>
            <li><a href="" @click.prevent="$router.push('/potfolio')">POT-FOLIO</a></li>
            <!-- <li><a href="https://opensea.io/collection/inkiesnfts">Opensea</a></li> -->
            <li><a href="https://mint.ak-labs.io/">Mint NFT</a></li>
          </ul>
          <div class="socials mt-4">
            <ul>
              <li><a class="social-btn" href="https://t.me/AKLABSofficialTG" target="_blank"><i class="fab fa-telegram" /></a></li>
              <li><a class="social-btn" href="https://twitter.com/AKLABS_official" target="_blank"><i class="fa-brands fa-x-twitter" /></a></li>
              <!-- <li><a href="https://www.ak-labs.io/" target="_blank"><i class="fa fa-globe" /></a></li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="copyrights style-1">
            <a href="mailto:aklabsofficial@gmail.com">aklabsofficial@gmail.com</a><br><br>
            © Copyright AKLABS 2024.<br>All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!--footer end-->
</template>

<script>
  import Vue from 'vue';
  import logo from '@/assets/png/logo.png';
  import dextools from '@/assets/png/dextools.png';
  import { scrollToTop } from '@/utils/scroll-to-top';

  export default Vue.extend({
    name: 'el-header',
    data: () => ({
      logo,
      dextools,
    }),
    methods: {
      scrollToTop,
    },
  });
</script>

<style>
  .social-btn {
    font-size: 36px;
  }
</style>

