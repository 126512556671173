/* eslint-disable */
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './interfaces';
import instance from '@/main';
import common from './modules/common';
import session from './modules/session';
import { nanoid } from 'nanoid';

Vue.use(Vuex);

const options: StoreOptions<RootState> = {
  state: {},
  modules: {
    common,
    session,
  },
};

export const store = new Vuex.Store<RootState>(options);
