/* eslint-disable */
import Vue from 'vue';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { ModuleState, RootState } from '@/store/interfaces';
import { api } from '@/services/api';
import instance from '@/main';
import { nanoid } from 'nanoid';
import { router } from '@/router';

export interface State extends ModuleState {
  isAuth: boolean;
  isFetching: boolean;
  user: any;
  users: any;
  leaderboards: any;
}

const dispatchToast = (
  { title, message }: {
    title: string;
    message: string;
  },
) => {
  (instance as any).$toasts.toast({
    id: nanoid(),
    intent: 'success',
    title,
    message,
  });
};

export default {
  namespaced: true,

  state: () => ({
    isAuth: false,
    user: {},
    users: [],
    leaderboards: [],
    isFetching: false,
  }) as State,

  actions: {
    async signUp({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } } = await api.create('signup', {
          payload: { ...credentials },
        });
        commit('setUser', data);
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your account has been successfully created. Please check your email for a confirmation message and follow the instructions to confirm your account.',
        });
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async signIn({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('login', {
          payload: { ...credentials },
        });
        commit('setUser', data);
        dispatchToast({
          title: 'Welcome back!',
          message: 'You have successfully logged in to your account.',
        });
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async forgotPassword({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('password', {
          payload: { ...credentials },
        });
        dispatchToast({
          title: 'Email sent!',
          message: 'We have sent you an email with instructions on how to reset your password. Please check your email and follow the steps to reset your password.',
        });
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async updatePassword({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.update('password', {
          payload: { ...credentials },
        });
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your password has been successfully reset. You can now log in to your account with your new password.',
        });
        router.push('/');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async tgSignIn({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('telegram_login', {
          payload: { ...credentials },
        });
        commit('setUser', data);
        dispatchToast({
          title: 'Welcome back!',
          message: 'You have successfully logged in to your account.',
        });
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async fetchUser({ state, commit, dispatch }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.find('v1/users/current');
        commit('updateUser', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
        localStorage.setItem('li', 'false');
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async fetchUsers({ state, commit, dispatch }, role) {
      Vue.set(state, 'isFetching', true);
      const endpoint = role
        ? `v1/admin/users?filter[role]=${role}`
        : 'v1/admin/users';

      try {
        const { data: { data } }  = await api.find(endpoint);
        Vue.set(state, 'users', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async fetchLeaderboards({ state, commit, dispatch }, type) {
      Vue.set(state, 'isFetching', true);
      const endpoint = type
        ? `v1/admin/users/leaderboard?sort_by=${type}&sort_dir=desc`
        : 'v1/admin/users/leaderboard';

      try {
        const { data: { data } }  = await api.find(endpoint);
        Vue.set(state, 'leaderboards', data);
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async updateUser({ state, commit, dispatch }, { credentials }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.update(`v1/users/${state.user.id}`, {
          payload: { ...credentials },
        });
        commit('updateUser', data);
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your profile was updated successfully.',
        });
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
        localStorage.setItem('li', 'false');
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async incrementScore({ state, commit, dispatch }, { user }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('v1/admin/users/increment_score', {
          payload: { ...user },
        });
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your score was updated successfully.',
        });
        dispatch('fetchUsers', 'army');
        dispatch('fetchLeaderboards', 'current_score');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async updateRole({ state, commit, dispatch }, { user }) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.create('v1/admin/users/update_roles', {
          payload: { ...user },
        });
        dispatchToast({
          title: 'Congratulations!',
          message: 'Your role was updated successfully.',
        });
        dispatch('fetchUsers', 'army');
        dispatch('fetchLeaderboards', 'current_score');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    async confirmUser({ state, commit, dispatch }, token) {
      Vue.set(state, 'isFetching', true);
      try {
        const { data: { data } }  = await api.find(`confirmation?confirmation_token=${token}`);
        commit('updateUser', data);
        router.push('profile');
      } catch ({ response: reason }) {
        commit('common/setErrors', reason, { root: true });
      } finally {
        Vue.set(state, 'isFetching', false);
      }
    },
    logout({ commit }) {
      commit('setUser', {});
      localStorage.setItem('li', 'false');
      router.push('/');
    },
  } as ActionTree<State, RootState>,


  getters: {
    isFetching: state => state.isFetching,
    getUser: state => state.user,
    getUsers: state => state.users,
    getLeaderboards: state => state.leaderboards,
    getToken: state => state.user && state.user?.token,
    getHoldings: state => state.user && state.user?.holdings,
    getWa: state => state.user && state.user['wallet_address'],
    isVip: (state) => {
      const walletAddress = state.user['wallet_address'] || '';
      const vipAddresses = [
        '0x06d57fff68cbabd3ff57a26dd4b3190d172b7302',
        '0x2cf7329be65c006e89e65387cc9c2fe2ebee375e',
        '0x927063ba6bc6b94d379f800a8e80825d78044a09',
        '0x7bcdc3700e88b5e872531e41436a17ea5af0dc13',
        '0x77167cc1de22fcaa7b25cc1b3836e6a93f9a4e78',
        '0x129461b2544fa462dee9e5bd0f26bb800a330e5d',
        '0x7375a1d5bb6e5d8fce260c8735bc80c09258b1c0',
        '0xe52e8df01f83f6ccedc73a11e44e775d771dc48d',
        '0xb483D0a406aFFa253DC75D997Bb26f1e4f1A1c4C',
      ].map(item => item.toLowerCase());
      return vipAddresses.includes(walletAddress.toLowerCase());
    },
    isGoldVip: (state) => {
      const walletAddress = state.user['wallet_address'] || '';
      const vipAddresses = [
        '0x24fc6633520a33645faa50632d239df574659fc2',
        '0xe31f96f6c24342ed961194d51d62ff4fe7e2a421',
        '0xcd3aaae991f1a51549b79c6631abcc1614f8e39b',
        '0xf0dcac44adfaa0ae03345fef18f82307e5738b80',
        '0x103da43bdc7d236f98515e09f3ee513008aa80fe',
        '0xaf2b57b9e25078528b4b0a02bed6d63335dfa6fd',
        '0x8e1ae31c2ec5e947791b99824a99bb8a0471d0a0',
        '0x1169dee92459644fd1b23c7831b2bc1b2b6d9e9e',
        '0x670b879a459c12787552c5a6662c80a17a9bb766',
        '0x75cbef944c78bd0a6c0e6e845aabb7d01437cf2c',
        '0xeb180d50ece5b253c09faa8ec14e47cc11c166c3',
        '0xc268c5e80080f6ef6073f88325841a046c08dfc5',
        '0x72f6c84af0c597db75a7969267e731c73fce6c76',
        '0x399d93ebbe7c3950e0bff87b8d7143d0c9c202ee',
        '0xd30b74274b12658e37dc5119ef857bd7c1e88097',
        '0xd7d5f5ba0ea7aa038712a0077af8ca684771204e',
        '0x5011dacac04a94b6b7628c6bb6c5a772c9ecc4a5',
        '0x06d57fff68cbabd3ff57a26dd4b3190d172b7302',
        '0x5441154B273fb482728356a94202Cb0C138E6c27',
        '0xaa02E3906ceF683d6baB696C35bbe46ECEb27691',
        '0x62E936733577a4f9f00387d435c9CCbFc9bAB78E',
        '0xc4f6cDEc5cf625D492a15dB9dFb1F0D61FbE8409',
        '0x77167cC1DE22FcAa7B25cc1B3836e6a93f9A4E78',
      ].map(item => item.toLowerCase());
      return vipAddresses.includes(walletAddress.toLowerCase());
    },
    isRecurring: (state) => {
      const walletAddress = state.user['wallet_address'] || '';
      const recurringAddresses = [
        '0xb483D0a406aFFa253DC75D997Bb26f1e4f1A1c4C',
        '0x77167cC1DE22FcAa7B25cc1B3836e6a93f9A4E78',
        '0x8399230cbD6449c7380531FD514E517ad1ec6c85',
        '0x7375a1D5Bb6E5D8FCE260C8735bC80C09258B1c0',
        '0x17D56C8DE46e1A91A157607c68225695F194fE74',
        '0x93e7A64e7f71Cc517bbEFC7Ef4f8688169AC30E8',
        '0xcd3aAaE991f1A51549B79c6631aBcC1614f8E39b',
        '0x103da43bDc7d236f98515e09f3eE513008aa80Fe',
        '0x4c32c929f744f88398aaa21b406e0cd6ad792607',
        '0xBD6c0Aa860150E873a421D815DAac531FB703cDf',
        '0x2cf7329Be65C006E89E65387cC9c2fe2eBEe375E',
        '0xEb180d50Ece5B253C09FAa8ec14E47cc11C166C3',
        '0xB6C0B75f1C861aE5fbB1669b24Ea7adc58297957',

      ].map(item => item.toLowerCase());
      return recurringAddresses.includes(walletAddress.toLowerCase());
    },
  } as GetterTree<State, RootState>,

  mutations: {
    setUser(state: State, data: any) {
      const token = data?.token;
      if (data && token) {
        localStorage.setItem('jwt', `Jh${token}`);
        localStorage.setItem('li', 'true');
      }
      Vue.set(state, 'user', data);
    },
    updateUser(state: State, data: any) {
      const token = localStorage.getItem('jwt')?.slice(2);
      const newData = {
        ...data,
        token,
      };
      Vue.set(state, 'user', newData);
    },
    updateUsers(state: State, data: any) {
      Vue.set(state, 'users', data);
    },
  } as MutationTree<State>,
};
