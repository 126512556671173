/* eslint-disable */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { store } from '@/store';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: '404',
    component: () => import('@/views/not-found').then(
      ({ NotFound }) => NotFound,
    ),
  },

  {
    path: '/',
    name: 'Home',
    alias: ['/register'],
    component: () => import('@/views/home').then(({ Home }) => Home),
    meta: {
      title: 'iNKiES Token: Resilient Crypto for Long-term Growth | Invest in Future Success',
      description: 'Discover iNKiES, the cryptocurrency designed to thrive in any market. Our three pillars of Funding, Events, and Treasury ensure growth and rewards for holders. Invest today for a secure, adaptable, and lucrative crypto future.'
    },
  },

  {
    path: '/potfolio',
    name: 'Potfolio',
    component: () => import('@/views/treasury').then(({ Treasury }) => Treasury),
    meta: {
      title: 'iNKiES POT-FOLIO: Your Path to Financial Freedom and Crypto Prosperity',
      description: 'Unlock the potential of the iNKiES POT-FOLIO - a dynamic engine for growth. Discover how holding ELROS tokens grants you a stake in strategic crypto investments, including blue-chip assets and carefully selected DeFi projects. Participate in community-driven decisions, embrace transparency, and embark on a journey towards financial empowerment.'
    },
  },

  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile').then(({ Profile }) => Profile),
  },

  {
    path: '/referral',
    name: 'Referral',
    component: () => import('@/views/referral').then(({ Referral }) => Referral),
  },

  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin').then(({ Admin }) => Admin),
  },

  {
    path: '/change-password',
    name: 'Change Password',
    component: () => import('@/views/change-password').then(({ ChangePassword }) => ChangePassword),
  },

  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/forgot-password').then(({ ForgotPassword }) => ForgotPassword),
  },
];

export const router = new VueRouter({
  linkActiveClass: 'is-current-route',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  mode: 'history'
});

router.beforeEach(async (to, from, next) => {
  let getToken = store.getters['session/getToken'];
  const jwtToken = localStorage.getItem('jwt') && !localStorage.getItem('jwt')!.includes('undefined');
  const loggedIn = localStorage.getItem('li');
  const { params, path, query, name } = to;
  document.title = to.meta?.title ?? 'Elros Crypto'

  if (!getToken && jwtToken && loggedIn === 'true') {
    await store.dispatch('session/fetchUser');
    getToken = store.getters['session/getToken'];
  }

  if (to?.name?.includes('Profile') && !getToken) {
    next('/');
  }

  if (to?.name?.includes('Referral') && !getToken) {
    next('/');
  }

  if (to?.name?.includes('Admin') && !getToken) {
    next('/');
  }

  if (to?.name?.includes('Vip Treasury') && !store.getters['session/isVip']) {
    next('/');
  }

  if (to?.name?.includes('Recurring Treasury') && !store.getters['session/isRecurring']) {
    next('/');
  }

  next();
});
