
  import Vue from 'vue';
  import { Header } from '@/components/shared/header';
  import { Footer } from '@/components/shared/footer';

  export default Vue.extend({
    name: 'elros-app',
    components: {
      'el-header': Header,
      'el-footer': Footer,
    },
    data: () => ({
      loading: false,
      isMobileView: false,
    }),
    mounted() {
      /* eslint-disable */
      const self = this;
      setTimeout(function () { self.loading = false; }, 500);
    },
  });
