import Jsona from 'jsona';
import {
  TJsonApiBody,
  TJsonaModel,
  TJsonaNormalizedIncludeNamesTree,
} from 'jsona/lib/JsonaTypes';

const Formatter = new Jsona();

const deserialize = (payload: TJsonApiBody) => Formatter.deserialize(payload);

const serialize = ({
  payload,
  relationships,
}: {
  payload: TJsonaModel | Array<TJsonaModel>;
  relationships: TJsonaNormalizedIncludeNamesTree;
}) => Formatter.serialize({
  stuff: payload,
  includeNames: relationships,
});

/* eslint-disable-next-line import/no-default-export */
export default {
  deserialize,
  serialize,
};
