
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-confirm-modal',

    props: {
      value: {
        required: true,
        type: Boolean,
      },
      cancelCta: {
        default: 'Nu, anuleaza',
        type: String,
      },
      confirmCta: {
        default: 'Da, seteaza',
        type: String,
      },
      hasConfirmCta: {
        required: true,
        type: Boolean,
      },
      reverse: {
        default: false,
        type: Boolean,
      },
    },

    methods: {
      onCancel() {
        this.$emit('input', false);
      },

      onConfirm() {
        this.$emit('on-confirm');
        this.$emit('input', false);
      },
    },
  });
