var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'es-skeleton__item': true,
    [`es-skeleton__item--${_vm.color}`]: true,
    [`es-skeleton__item--${_vm.ratio}`]: _vm.ratio,
    [`es-skeleton__item--${_vm.shape}`]: true,
  },style:({
    height: _vm.height,
    paddingBottom: _vm.localRatio,
    width: _vm.width,
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }