import qs from 'qs';
import { AxiosRequestConfig } from 'axios';
import JsonApiFormatter from '@/services/api/formatters/json-api';
import ContentTypes from '@/constants/api-content-types';

/* eslint-disable-next-line import/no-default-export */
export default (request: AxiosRequestConfig) => {
  /* eslint-disable no-param-reassign */
  request.baseURL = 'https://aklabs-api-0656cc5ebbcb.herokuapp.com/api/';

  const token = localStorage.getItem('jwt');

  request.headers = {
    accept: request.headers?.accept || 'application/vnd.api+json, application/json',
    'content-type': request.headers?.['content-type'] || 'application/vnd.api+json',
    'access-control-allow-origin': '*',
    Authorization: `Bearer ${token?.slice(2)}`,
  };

  request.paramsSerializer = params => qs.stringify(params, { encode: false, arrayFormat: 'brackets' });

  request.transformRequest = [(data, headers) => {
    if (data && headers['content-type'].includes(ContentTypes.jsonapi)) {
      return JSON.stringify(JsonApiFormatter.serialize(data));
    }
    return JSON.stringify(data);
  }];
  /* eslint-enable no-param-reassign */
  return request;
};
